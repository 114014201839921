import Vue from "vue";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import SortedTablePlugin from "vue-sorted-table";
import Branches from "@/components/widgets/branches";
import Customers from "@/components/widgets/customers";
import AddItem from "./components/add";
import Loading from "@/components/widgets/Loading.vue";
import CustomerCreate from "@/components/customer/customer-create";
import provinces from "@/data/provinces.json";
import VueSimpleSuggest from "vue-simple-suggest";
import SettingPopup from '@/components/dialogs/quotation-item'

const _ = require("lodash");
import moment from "moment";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    DatePicker,
    Branches,
    customers: Customers,
    "add-item": AddItem,
    Loading,
    CustomerCreate,
    VueSimpleSuggest,
    SettingPopup
  },
  mixins: [validationMixin],
  validations: {
    form: {
      quotation_date: { required },
      province: { required },
      district: { required },
      sale_id: { required },
      payment_type: { required },
      bank_account_detail: { required },
      vatable: { required },
      reason: { required },
      credit_detail: {
        required: requiredIf(function (value, vm) {
          return !!vm.payment_type === 1;
        }),
      },
    },
  },
  props: {
    item: Object,
  },
  data() {
    return {
      provinces: provinces,
      districts: [
        {
          text: "--- กรุณาเลือก ---",
          value: null,
        },
      ],
      isSettingOpened: false,
      settingData: {},
      settingIndex: -1,
      isCreateCustomerOpened: false,
      role: "",
      discount: 0,
      creator_id: null,
      after_percent: null,
      perPage: 6,
      sortBy: "name",
      currentPage: 1,
      sortDesc: false,
      sortable: true,
      source: "1",
      loading: false,
      product_opened: false,
      branch_opend: false,
      customer_opened: false,
      fields: [
        {
          key: "name",
          label: "ลูกค้า",
          sortable: false,
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "td-top text-xs-1/2",
        },
        {
          key: "company_name",
          label: "บริษัท",
          sortable: false,
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "td-top text-xs-1/2",
        },
        {
          key: "address",
          label: "ที่อยู่",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "contact",
          label: "ติดต่อ",
          sortable: false,
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-left text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "action",
          label: "#",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
        },
      ],
      options: [
        { value: 0, text: "ไม่มี Vat" },
        { value: 1, text: "แยก Vat" },
        { value: 2, text: "รวม Vat" },
      ],
      paymentOptions: [
        { value: 0, text: "เงินสด" },
        { value: 1, text: "เครดิต" },
      ],
      customer_items: [],
      product_fields: [
        {
          key: "idx",
          label: "#",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "td-top text-xs-1/2",
        },
        {
          key: "code",
          label: "รหัสสินค้า",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "description",
          label: "รายละเอียด",
          sortable: false,
          thClass: "text-center font-weight-bold text-xs-1/2",
          tdClass: "td-top text-xs-1/2",
          thStyle: { width: "20%" },
        },
        {
          key: "length",
          label: "ความยาว",
          sortable: false,
          thClass: "text-center font-weight-bold text-xs-1/2",
          tdClass: "text-right text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "quantity",
          label: "จำนวน",
          sortable: false,
          thClass: "text-center font-weight-bold text-xs-1/2",
          tdClass: "text-right text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "unit_name",
          label: "หน่วย",
          sortable: false,
          thClass: "text-center font-weight-bold text-xs-1/2",
          tdClass: "text-right text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "total_length",
          label: "ปริมาณรวม",
          sortable: false,
          thClass: "text-center font-weight-bold text-xs-1/2",
          tdClass: "text-right text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "unit_price",
          label: "ราคาต่อหน่วย",
          sortable: false,
          thClass: "text-center font-weight-bold text-xs-1/2",
          tdClass: "text-right text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "total_price",
          label: "ราคารวม",
          sortable: false,
          thClass: "text-center font-weight-bold text-xs-1/2",
          tdClass: "text-right text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "action",
          label: "#",
          thClass: "text-center font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
        },
      ],
      product_items: [],
      only_product_items: [],
      seller_list: [],
      bank_account_list: [],
      form: {
        project_name: "",
        products: "",
        customer: "",
        quotation_date: new Date(),
        freeze_price_day: null,
        production_period: null,
        deposit_percent: null,
        bank_account_detail: null,
        vatable: 0,
        payment_type: 0,
        credit_detail: null,
        deposit_amount: "",
        remark: null,
        sale_id: null,
        province: null,
        district: null,
        reason: 1,
      },
    };
  },
  watch: {
    "form.deposit_percent"(new_val) {
      if (new_val) {
        this.$nextTick(() => {
          this.form.deposit_amount = "";
        });

        if (new_val > 100) {
          this.$nextTick(() => {
            this.form.deposit_percent = 100;
          });
        }

        this.after_percent = 100 - parseInt(new_val);
      } else {
        this.after_percent = 0;
      }
    },
    "form.deposit_amount"(new_val, old_val) {
      let newValF = parseFloat(new_val);
      let totalF = parseFloat(this.total);

      if (new_val) {
        this.form.deposit_percent = 0;
      }
      if (newValF < 0) {
        this.$nextTick(() => {
          this.form.deposit_amount = 0;
        });
      }

      if (newValF > totalF) {
        this.$nextTick(() => {
          this.form.deposit_amount = totalF;
        });
      }
    },
    "form.vatable"(new_val) {
      // if (new_val === 2) {
      //   this.discount = 0;
      // }
    },
    "form.payment_type"(new_val) {
      if (new_val && !this.$route.params.id) {
        this.form.deposit_percent = null;
        this.form.after_percent = null;
        this.form.credit_detail = null;
      }
      this.onLoadBankAccount();
    },
    product_items(new_val) {
      if (new_val) {
        this.only_product_items = this.product_items.filter(
          (x) => x.type === 1
        );
      }
    },
  },
  async mounted() {
    await this.getRole();
    await this.onLoadEmployee();
    await this.onLoadBankAccount();
    await this.onInitData();

    setTimeout(() => {
      if (!this.form.bank_account_detail) {
        this.form.bank_account_detail = this.bank_account_list.filter(
          (a) => a.is_primary
        )[0]?.id;
      }
    }, 500);
  },
  computed: {
    subtotal: function () {
      const total = this.product_items.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.total_price);
      }, 0);
      if (this.form.vatable === 2) {
        return parseFloat(total);
      } else {
        return total;
      }
    },
    beforeVat: function () {
      const total = this.product_items.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.total_price);
      }, 0);
      if (this.form.vatable === 2) {
        return (parseFloat(total - this.discount) * 100) / 107;
      } else {
        return total - this.discount;
      }
    },
    vat: function () {
      const total = this.product_items.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.total_price);
      }, 0);
      if (this.form.vatable === 1) {
        return parseFloat(total - this.discount) * 0.07;
      } else if (this.form.vatable === 2) {
        // return (parseFloat(total) * 7) / 107;
        return (parseFloat(total - this.discount) * 7) / 107;
      } else {
        return 0;
      }
    },
    total: function () {
      const total = this.product_items.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.total_price);
      }, 0);

      if (this.form.vatable === 1) {
        const returnData = parseFloat(total - this.discount) * 1.07;
        return returnData;
      } else if (this.form.vatable === 2) {
        const returnData = parseFloat(total - this.discount);
        return returnData;
      } else {
        const returnData = total - this.discount;
        return returnData;
      }
    },
  },
  methods: {
    checkSelect(e, index) {
      var checkIfSkuExisted = this.skuOptions.findIndex(p => p.full_code === e);
      if(checkIfSkuExisted === -1){
        this.product_items[index].sku_id = null
        this.product_items[index].code_description = ""
      }
      else{
        this.product_items[index].sku_id = this.skuOptions[checkIfSkuExisted].id
        this.product_items[index].code_description = this.skuOptions[checkIfSkuExisted].name
      }
    },
    customFilterFunction(){
      return this.skuOptions
    },
    getProvinces() {
      let provinceList = [
        ...new Set(this.provinces.map((element) => element.province)),
      ];
      provinceList = provinceList.map(function (a) {
        var tmp = {};
        tmp["text"] = a;
        tmp["value"] = a;
        return tmp;
      });
      var tmp = {};
      tmp["text"] = "--- กรุณาเลือก ---";
      tmp["value"] = null;
      provinceList.splice(0, 0, tmp);
      return provinceList;
    },
    onProvinceChange(e) {
      let amphoeDup = this.provinces.filter(
        (word) => word.province === e.toString()
      );
      let amphoeList = [...new Set(amphoeDup.map((element) => element.amphoe))];
      var tmp = {};
      tmp["text"] = "--- กรุณาเลือก ---";
      tmp["value"] = null;
      amphoeList.splice(0, 0, tmp);
      this.districts = amphoeList;
    },
    percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    },
    toFixedIfNecessary(value) {
      return +parseFloat(value).toFixed(2);
    },
    handleCreateEvent(e) {
      this.customer_items = [];
      if (e.data) {
        this.customer_items.push(e.data);
      }
    },
    lengthChanged(new_val, index) {
      this.reCalculate(index);
    },
    quantityChanged(new_val, index) {
      this.reCalculate(index);
    },
    unitPriceChanged(new_val, index) {
      this.reCalculate(index);
    },
    reCalculate(index) {
      const obj = this.product_items[index];
      if (parseFloat(obj.length) > 0 && parseFloat(obj.quantity) > 0) {
        obj.total_length = (
          parseFloat(obj.length) * parseFloat(obj.quantity)
        ).toFixed(2);
        obj.total_price = (
          parseFloat(obj.total_length) * parseFloat(obj.unit_price)
        ).toFixed(2);
      } else if (obj.length === "0") {
        obj.total_length = parseFloat(obj.quantity);
        obj.total_price =
          parseFloat(obj.total_length) * parseFloat(obj.unit_price);
      } else if (obj.quantity === "0") {
        obj.total_length = parseFloat(obj.length);
        obj.total_price =
          parseFloat(obj.total_length) * parseFloat(obj.unit_price);
      } else {
        obj.total_length = 1;
        obj.total_price =
          parseFloat(obj.total_length) * parseFloat(obj.unit_price);
      }

      // if (this.form.vatable === 1) {
      //   this.total = parseFloat(
      //     parseFloat(this.subtotal) -
      //       parseFloat(this.discount) +
      //       this.calculateVat()
      //   );
      // } else if (this.form.vatable === 2) {
      //   this.total = parseFloat(
      //     parseFloat(this.subtotal) - parseFloat(this.discount)
      //   );
      //   this.subtotal = this.total - this.calculateVat();
      // } else {
      //   this.total = parseFloat(
      //     parseFloat(this.subtotal) -
      //       parseFloat(this.discount) +
      //       this.calculateVat()
      //   );
      // }
    },
    calculateVat() {
      if (this.form.vatable === 1) {
        return (parseFloat(this.subtotal) - parseFloat(this.discount)) * 0.07;
      } else if (this.form.vatable === 2) {
        return this.total * (7 / 107);
      } else {
        return 0;
      }
      // return this.form.vatable === 1
      //   ? ((parseFloat(this.subtotal) - parseFloat(this.discount)) * 0.07)
      //   : 0;
    },
    async getRole() {
      const res = await this.$store.dispatch(
        "Auth/getRole",
        {},
        { root: true }
      );
      this.creator_id = res.data.id;
      this.role = res.data.role;
    },
    onopencustomer() {
      this.customer_opened = !this.customer_opened;
    },
    onaftercustomer(e) {
      this.customer_opened = false;
      this.source = "2";
      this.customer_items = [];
      if (e.data) {
        this.customer_items.push(e.data);
      }
    },
    onSettingClose(e) {
      this.isSettingOpened = false;
      if (e.status) {
        this.product_items[e.index] = e.data
      }
    },
    onopenbranch() {
      this.branch_opend = !this.branch_opend;
    },
    onafterbranch(e) {
      this.source = "1";
      this.branch_opend = false;
      this.customer_items = [];
      if (e.data) {
        this.customer_items.push(e.data);
      }
    },
    onremove(uuid) {
      const index = _.findIndex(this.customer_items, function (o) {
        return o.uuid == uuid;
      });
      this.customer_items.splice(index, 1);
    },
    onremove_product(index) {
      this.product_items.splice(index, 1);
    },
    oninsert_product(index, type) {
      var blank_row = {
        code: "",
        code_description: "",
        description: "",
        length: "0",
        quantity: "0",
        total_length: "0",
        unit_price: "0",
        total_price: "0",
        unit_name: "",
        type: type,
        sku_id: null,
        is_insulator: false,
        isPE: true,
        isPU: false,
        thickness: null,
        insulator_material: null,
        insulator_detail: "",
        insulator_head: "",
        insulator_tail: "",

        is_curve: false,
        curve_weight: "",
        curve_start: "",
        curve_gap: "",
        curve_repeat: "",
      };
      this.product_items.splice(index + 1, 0, blank_row);
    },
    oncopy_product(index) {
      const currentProducts = this.product_items;
      const targetProduct = currentProducts[index];

      var copied_row = {
        code: targetProduct.code,
        code_description: targetProduct.code_description,
        description: targetProduct.description,
        length: targetProduct.length,
        quantity: targetProduct.quantity,
        total_length: targetProduct.total_length,
        unit_price: targetProduct.unit_price,
        total_price: targetProduct.total_price,
        unit_name: targetProduct.unit_name,
        type: targetProduct.type,
        sku_id: targetProduct.sku_id,
        is_insulator: targetProduct.is_insulator,
        isPE: targetProduct.isPE,
        isPU: targetProduct.isPU,
        thickness: targetProduct.thickness,
        insulator_material: targetProduct.insulator_material,
        insulator_detail: targetProduct.insulator_detail,
        insulator_head: targetProduct.insulator_head,
        insulator_tail: targetProduct.insulator_tail,

        is_curve: targetProduct.is_curve,
        curve_weight: targetProduct.curve_weight,
        curve_start: targetProduct.curve_start,
        curve_gap: targetProduct.curve_gap,
        curve_repeat: targetProduct.curve_repeat,
      };

      console.log(copied_row)

      this.product_items.splice(index + 1, 0, copied_row);
    },
    onopenproduct() {
      var blank_row = {
        code: "",
        code_description: "",
        description: "",
        length: "0",
        quantity: "0",
        total_length: "0",
        unit_price: "0",
        total_price: "0",
        unit_name: "",
        type: 1,
        sku_id: null,
        is_insulator: false,
        isPE: true,
        isPU: false,
        thickness: null,
        insulator_material: null,
        insulator_detail: "",
        insulator_head: "",
        insulator_tail: "",

        is_curve: false,
        curve_weight: "",
        curve_start: "",
        curve_gap: "",
        curve_repeat: "",
      };
      this.product_items.splice(0, 0, blank_row);

      // this.product_items.push(blank_row);
    },
    onopentopic() {
      var blank_row = {
        code: "",
        code_description: "",
        description: "",
        length: "0",
        quantity: "0",
        total_length: "0",
        unit_price: "0",
        total_price: "0",
        unit_name: "",
        type: 2,
        sku_id: null,
        is_insulator: false,
        isPE: true,
        isPU: false,
        thickness: null,
        insulator_material: null,
        insulator_detail: "",
        insulator_head: "",
        insulator_tail: "",

        is_curve: false,
        curve_weight: "",
        curve_start: "",
        curve_gap: "",
        curve_repeat: "",
      };
      this.product_items.splice(0, 0, blank_row);
    },
    previous() {
      this.$emit("componentInfo", {
        status: true,
        component: "invoice-list",
      });
    },
    onCancel() {
      this.$router.push("/quotation");
    },
    async onLoadEmployee() {
      try {
        this.loading = true;
        const res = await this.$axios.get(
          this.$host + "/api/v1/document/quotation/branch/employee",
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.seller_list = res.data;
        this.form.sale_id = this.creator_id;
        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onLoadBankAccount() {
      try {
        this.loading = true;
        const res = await this.$axios.get(
          this.$host +
            "/api/v1/document/quotation/branch/bank_accounts/type/" +
            this.form.payment_type
        );

        this.bank_account_list = res.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onInitData() {
      try {
        const id = this.$route.params.id;
        if (id) {
          const res = await this.$axios.get(
            this.$host + "/api/v1/document/quotation/" + id,
            {
              headers: {},
            }
          );
          const data = res.data;
          this.product_items = data.products;
          this.customer_items.push(data.customer_detail);
          this.form = data;
          this.form.reason = 1;
          this.form.quotation_date = new Date(data.quotation_date);
          this.discount = data.discount;

          if (data.province) {
            this.onProvinceChange(data.province);
          }
        }
      } catch (error) {
        this.loading = false;
        // this.onExceptionHandler(error);
        this.$router.push("/error-404")
      }
    },
    async onSubmitForm() {
      if (this.$route.params.id) {
        if (this.form.quotation_date === null) {
          this.onExceptionHandler("กรุณาเลือกวันที่ใบเสนอราคา");
          return false;
        }
        if (this.customer_items.length === 0) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลลูกค้า");
          return false;
        }

        if (this.product_items.length === 0) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลสินค้าหรือหัวข้อ");
          return false;
        }
        else{
          const codeItems = this.product_items.filter(item => {
            return item.type === 1
          }
            )
            
          if (codeItems.length > 0) {
            // Extract the `code` values from array2
            const codeInArray = this.skuOptions.map(obj => obj.full_code)
            const areCodesInArray = codeItems.every(obj => codeInArray.includes(obj.code))
            if(!areCodesInArray){
              this.onExceptionHandler("กรุณาตรวจสอบข้อมูล \"รหัสสินค้า\" ที่ท่านกรอก");
              return
            }
          }
        }

        if (this.form.province === null || this.form.district === null) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลจังหวัดและอำเภอ");
          return false;
        }

        if (this.form.sale_id === null) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลผู้ขาย");
          return false;
        }

        if (this.form.bank_account_detail === undefined) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลบัญชี");
          return false;
        }

        this.form.products = JSON.stringify(this.product_items);
        this.form.customer = JSON.stringify(this.customer_items);

        try {
          this.loading = true;
          const formData = new FormData();

          if (this.$route.name === "revise-quotation") {
            formData.append("quotation_date", moment().format("YYYY-MM-DD"));
            formData.append("reason", this.form.reason);
          }

          formData.append(
            "freeze_price_day",
            this.form.freeze_price_day ? this.form.freeze_price_day : ""
          );
          formData.append(
            "production_period",
            this.form.production_period ? this.form.production_period : ""
          );
          formData.append(
            "deposit_percent",
            this.form.deposit_percent ? this.form.deposit_percent : ""
          );
          formData.append(
            "deposit_amount",
            this.form.deposit_amount ? this.form.deposit_amount : ""
          );
          formData.append(
            "bank_account_detail",
            this.form.bank_account_detail ? this.form.bank_account_detail : ""
          );
          formData.append("vatable", this.form.vatable);

          formData.append("subtotal", this.subtotal);
          formData.append("discount", this.discount);
          formData.append("grandtotal", this.total);

          formData.append("products", this.form.products);
          formData.append("customer", this.form.customer);
          formData.append("sale_id", this.form.sale_id);
          formData.append("remark", this.form.remark ? this.form.remark : "");
          formData.append("payment_type", this.form.payment_type);
          formData.append(
            "credit_detail",
            this.form.credit_detail ? this.form.credit_detail : ""
          );
          formData.append(
            "project_name",
            this.form.project_name ? this.form.project_name : ""
          );
          formData.append(
            "district",
            this.form.district ? this.form.district : ""
          );
          formData.append(
            "province",
            this.form.province ? this.form.province : ""
          );
          if(this.$route.query.lead_id){
            formData.append("lead_uuid", this.$route.query.lead_id);
          }

          let result = {};

          if (this.$route.name === "revise-quotation") {
            result = await Vue.prototype.$axios.post(
              `${Vue.prototype.$host}/api/v1/document/reviseQuotation/${this.$route.params.id}`,
              formData
            );
          } else {
            result = await Vue.prototype.$axios.post(
              `${Vue.prototype.$host}/api/v1/document/quotation/edit/${this.$route.params.id}`,
              formData
            );
          }

          this.loading = false;

          setTimeout(() => {
            if (result.status === 201 || result.status === 200) {
              this.$swal({
                width: "29rem",
                padding: "1.825em",
                html:
                  '<div class="d-flex flex-row align-items-center">' +
                  '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
                  '<div class="d-flex flex-column">' +
                  '<span class="font-bold text-base py-3 text-left">แจ้งเตือน</span>' +
                  `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>` +
                  "</div>" +
                  "</div>",
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: "swal2-custom-rounded",
                  closeButton: "text-3xl swal2-custom-close",
                  actions: "justify-content-end",
                  contant: "d-flex flex-row justify-content-around px-2",
                  title: "d-none",
                  confirmButton:
                    "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton:
                    "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
              }).then((result) => {
                this.customer_items = [];
                this.product_items = [];
                this.form.products = "";
                this.form.customer = "";

                this.$router.push("/quotation");
              });
            }
          });
        } catch (error) {
          this.loading = false;
          this.onExceptionHandler(error.message);
        }
      } else {
        if (this.form.quotation_date === null) {
          this.onExceptionHandler("กรุณาเลือกวันที่ใบเสนอราคา");
          return false;
        }
        if (this.customer_items.length === 0) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลลูกค้า");
          return false;
        }

        if (this.product_items.length === 0) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลสินค้าหรือหัวข้อ");
          return false;
        }
        else{
          const codeItems = this.product_items.filter(item => {
            return item.type === 1
          }
            )
            
          if (codeItems.length > 0) {
            // Extract the `code` values from array2
            const codeInArray = this.skuOptions.map(obj => obj.full_code)
            const areCodesInArray = codeItems.every(obj => codeInArray.includes(obj.code))
            if(!areCodesInArray){
              this.onExceptionHandler("กรุณาตรวจสอบข้อมูล \"รหัสสินค้า\" ที่ท่านกรอก");
              return
            }
          }
        }

        if (this.form.province === null || this.form.district === null) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลจังหวัดและอำเภอ");
          return false;
        }

        if (this.form.sale_id === null) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลผู้ขาย");
          return false;
        }

        if (this.form.bank_account_detail === undefined) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลบัญชี");
          return false;
        }

        this.form.products = JSON.stringify(this.product_items);
        this.form.customer = JSON.stringify(this.customer_items);
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append(
            "quotation_date",
            moment(this.form.quotation_date).format("YYYY-MM-DD")
          );
          formData.append(
            "freeze_price_day",
            this.form.freeze_price_day ? this.form.freeze_price_day : ""
          );
          formData.append(
            "production_period",
            this.form.production_period ? this.form.production_period : ""
          );
          formData.append(
            "deposit_percent",
            this.form.deposit_percent ? this.form.deposit_percent : ""
          );
          formData.append(
            "deposit_amount",
            this.form.deposit_amount ? this.form.deposit_amount : ""
          );
          formData.append(
            "bank_account_detail",
            this.form.bank_account_detail ? this.form.bank_account_detail : ""
          );
          formData.append("vatable", this.form.vatable);

          formData.append("subtotal", this.subtotal);
          formData.append("discount", this.discount);
          formData.append("grandtotal", this.total);

          formData.append("products", this.form.products);
          formData.append("customer", this.form.customer);
          formData.append("sale_id", this.form.sale_id);
          formData.append("remark", this.form.remark ? this.form.remark : "");
          formData.append("payment_type", this.form.payment_type);
          formData.append(
            "credit_detail",
            this.form.credit_detail ? this.form.credit_detail : ""
          );
          formData.append(
            "project_name",
            this.form.project_name ? this.form.project_name : ""
          );
          formData.append(
            "district",
            this.form.district ? this.form.district : ""
          );
          formData.append(
            "province",
            this.form.province ? this.form.province : ""
          );
          if(this.$route.query.lead_id){
            formData.append("lead_uuid", this.$route.query.lead_id);
          }

          const result = await Vue.prototype.$axios.post(
            `${Vue.prototype.$host}/api/v1/document/quotation`,
            formData
          );

          // if (this.item?.uuid) {
          //   formData.append('source', this.source)
          //   result = await this.$store.dispatch('Invoice/update', {form: formData, Id: this.item.uuid}, { root: true })
          // } else {
          //   formData.append('source', this.source)
          //   result = await this.$store.dispatch('Invoice/create', formData, { root: true })
          // }
          this.loading = false;

          setTimeout(() => {
            if (result.status === 201 || result.status === 200) {
              this.$swal({
                width: "29rem",
                padding: "1.825em",
                html:
                  '<div class="d-flex flex-row align-items-center">' +
                  '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
                  '<div class="d-flex flex-column">' +
                  '<span class="font-bold text-base py-3 text-left">แจ้งเตือน</span>' +
                  `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>` +
                  "</div>" +
                  "</div>",
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: "swal2-custom-rounded",
                  closeButton: "text-3xl swal2-custom-close",
                  actions: "justify-content-end",
                  contant: "d-flex flex-row justify-content-around px-2",
                  title: "d-none",
                  confirmButton:
                    "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton:
                    "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
              }).then((result) => {
                this.customer_items = [];
                this.product_items = [];
                this.form.products = "";
                this.form.customer = "";

                this.$router.push("/quotation");
              });
            }
          });
        } catch (error) {
          this.loading = false;
          this.onExceptionHandler(error.message);
        }
      }
    },
    async onConfirmSubmitForm2() {
      this.$swal({
        title: "แจ้งเตือน",
        text: "คุณต้องการส่งใบเสนอราคาเพื่อตรวจสอบใช่หรือไม่",
        type: "info",
        showCancelButton: true,
        confirmButtonText: "ใช่",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCloseButton: true,
        width: "23rem",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: "d-none",
          confirmButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
          cancelButton:
            "btn btn-fw font-prompt-light text-sm font-normal rounded-lg",
        },
      }).then((result) => {
        if (result.value) {
          this.onSubmitForm2();
        }
      });
    },
    async onSubmitForm2() {
      if (this.$route.params.id) {
        if (this.form.quotation_date === null) {
          this.onExceptionHandler("กรุณาเลือกวันที่ใบเสนอราคา");
          return false;
        }
        if (this.customer_items.length === 0) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลลูกค้า");
          return false;
        }

        if (this.product_items.length === 0) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลสินค้าหรือหัวข้อ");
          return false;
        }
        else{
          const codeItems = this.product_items.filter(item => {
            return item.type === 1
          }
            )
            
          if (codeItems.length > 0) {
            // Extract the `code` values from array2
            const codeInArray = this.skuOptions.map(obj => obj.full_code)
            const areCodesInArray = codeItems.every(obj => codeInArray.includes(obj.code))
            if(!areCodesInArray){
              this.onExceptionHandler("กรุณาตรวจสอบข้อมูล \"รหัสสินค้า\" ที่ท่านกรอก");
              return
            }
          }
        }

        if (this.form.province === null || this.form.district === null) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลจังหวัดและอำเภอ");
          return false;
        }

        if (this.form.sale_id === null) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลผู้ขาย");
          return false;
        }

        if (this.form.bank_account_detail === undefined) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลบัญชี");
          return false;
        }

        this.form.products = JSON.stringify(this.product_items);
        this.form.customer = JSON.stringify(this.customer_items);

        try {
          this.loading = true;
          const formData = new FormData();

          if (this.$route.name === "revise-quotation") {
            formData.append("quotation_date", moment().format("YYYY-MM-DD"));
            formData.append("reason", this.form.reason);
          }
          formData.append("for_approve", true);

          formData.append(
            "freeze_price_day",
            this.form.freeze_price_day ? this.form.freeze_price_day : ""
          );
          formData.append(
            "production_period",
            this.form.production_period ? this.form.production_period : ""
          );
          formData.append(
            "deposit_percent",
            this.form.deposit_percent ? this.form.deposit_percent : ""
          );
          formData.append(
            "deposit_amount",
            this.form.deposit_amount ? this.form.deposit_amount : ""
          );
          formData.append(
            "bank_account_detail",
            this.form.bank_account_detail ? this.form.bank_account_detail : ""
          );
          formData.append("vatable", this.form.vatable);

          formData.append("subtotal", this.subtotal);
          formData.append("discount", this.discount);
          formData.append("grandtotal", this.total);

          formData.append("products", this.form.products);
          formData.append("customer", this.form.customer);
          formData.append("sale_id", this.form.sale_id);
          formData.append("remark", this.form.remark ? this.form.remark : "");
          formData.append("payment_type", this.form.payment_type);
          formData.append(
            "credit_detail",
            this.form.credit_detail ? this.form.credit_detail : ""
          );
          formData.append(
            "project_name",
            this.form.project_name ? this.form.project_name : ""
          );
          formData.append(
            "district",
            this.form.district ? this.form.district : ""
          );
          formData.append(
            "province",
            this.form.province ? this.form.province : ""
          );
          if(this.$route.query.lead_id){
            formData.append("lead_uuid", this.$route.query.lead_id);
          }

          let result = {};

          if (this.$route.name === "revise-quotation") {
            result = await Vue.prototype.$axios.post(
              `${Vue.prototype.$host}/api/v1/document/reviseQuotation/${this.$route.params.id}`,
              formData
            );
          } else {
            result = await Vue.prototype.$axios.post(
              `${Vue.prototype.$host}/api/v1/document/quotation/edit/${this.$route.params.id}`,
              formData
            );
          }

          this.loading = false;

          setTimeout(() => {
            if (result.status === 201 || result.status === 200) {
              this.$swal({
                width: "29rem",
                padding: "1.825em",
                html:
                  '<div class="d-flex flex-row align-items-center">' +
                  '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
                  '<div class="d-flex flex-column">' +
                  '<span class="font-bold text-base py-3 text-left">แจ้งเตือน</span>' +
                  `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>` +
                  "</div>" +
                  "</div>",
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: "swal2-custom-rounded",
                  closeButton: "text-3xl swal2-custom-close",
                  actions: "justify-content-end",
                  contant: "d-flex flex-row justify-content-around px-2",
                  title: "d-none",
                  confirmButton:
                    "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton:
                    "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
              }).then((result) => {
                this.customer_items = [];
                this.product_items = [];
                this.form.products = "";
                this.form.customer = "";

                this.$router.push("/quotation");
              });
            }
          });
        } catch (error) {
          this.loading = false;
          this.onExceptionHandler(error.message);
        }
      } else {
        if (this.form.quotation_date === null) {
          this.onExceptionHandler("กรุณาเลือกวันที่ใบเสนอราคา");
          return false;
        }
        if (this.customer_items.length === 0) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลลูกค้า");
          return false;
        }

        if (this.product_items.length === 0) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลสินค้าหรือหัวข้อ");
          return false;
        }
        else{
          const codeItems = this.product_items.filter(item => {
            return item.type === 1
          }
            )
            
          if (codeItems.length > 0) {
            // Extract the `code` values from array2
            const codeInArray = this.skuOptions.map(obj => obj.full_code)
            const areCodesInArray = codeItems.every(obj => codeInArray.includes(obj.code))
            if(!areCodesInArray){
              this.onExceptionHandler("กรุณาตรวจสอบข้อมูล \"รหัสสินค้า\" ที่ท่านกรอก");
              return
            }
          }
        }

        if (this.form.province === null || this.form.district === null) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลจังหวัดและอำเภอ");
          return false;
        }

        if (this.form.sale_id === null) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลผู้ขาย");
          return false;
        }

        if (this.form.bank_account_detail === undefined) {
          this.onExceptionHandler("กรุณาเลือกข้อมูลบัญชี");
          return false;
        }

        this.form.products = JSON.stringify(this.product_items);
        this.form.customer = JSON.stringify(this.customer_items);
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("for_approve", true);

          formData.append(
            "quotation_date",
            moment(this.form.quotation_date).format("YYYY-MM-DD")
          );
          formData.append(
            "freeze_price_day",
            this.form.freeze_price_day ? this.form.freeze_price_day : ""
          );
          formData.append(
            "production_period",
            this.form.production_period ? this.form.production_period : ""
          );
          formData.append(
            "deposit_percent",
            this.form.deposit_percent ? this.form.deposit_percent : ""
          );
          formData.append(
            "deposit_amount",
            this.form.deposit_amount ? this.form.deposit_amount : ""
          );
          formData.append(
            "bank_account_detail",
            this.form.bank_account_detail ? this.form.bank_account_detail : ""
          );
          formData.append("vatable", this.form.vatable);

          formData.append("subtotal", this.subtotal);
          formData.append("discount", this.discount);
          formData.append("grandtotal", this.total);

          formData.append("products", this.form.products);
          formData.append("customer", this.form.customer);
          formData.append("sale_id", this.form.sale_id);
          formData.append("remark", this.form.remark ? this.form.remark : "");
          formData.append("payment_type", this.form.payment_type);
          formData.append(
            "credit_detail",
            this.form.credit_detail ? this.form.credit_detail : ""
          );
          formData.append(
            "project_name",
            this.form.project_name ? this.form.project_name : ""
          );
          formData.append(
            "district",
            this.form.district ? this.form.district : ""
          );
          formData.append(
            "province",
            this.form.province ? this.form.province : ""
          );
          if(this.$route.query.lead_id){
            formData.append("lead_uuid", this.$route.query.lead_id);
          }

          const result = await Vue.prototype.$axios.post(
            `${Vue.prototype.$host}/api/v1/document/quotation`,
            formData
          );

          // if (this.item?.uuid) {
          //   formData.append('source', this.source)
          //   result = await this.$store.dispatch('Invoice/update', {form: formData, Id: this.item.uuid}, { root: true })
          // } else {
          //   formData.append('source', this.source)
          //   result = await this.$store.dispatch('Invoice/create', formData, { root: true })
          // }
          this.loading = false;

          setTimeout(() => {
            if (result.status === 201 || result.status === 200) {
              this.$swal({
                width: "29rem",
                padding: "1.825em",
                html:
                  '<div class="d-flex flex-row align-items-center">' +
                  '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
                  '<div class="d-flex flex-column">' +
                  '<span class="font-bold text-base py-3 text-left">แจ้งเตือน</span>' +
                  `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>` +
                  "</div>" +
                  "</div>",
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: "swal2-custom-rounded",
                  closeButton: "text-3xl swal2-custom-close",
                  actions: "justify-content-end",
                  contant: "d-flex flex-row justify-content-around px-2",
                  title: "d-none",
                  confirmButton:
                    "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton:
                    "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
              }).then((result) => {
                this.customer_items = [];
                this.product_items = [];
                this.form.products = "";
                this.form.customer = "";

                this.$router.push("/quotation");
              });
            }
          });
        } catch (error) {
          this.loading = false;
          this.onExceptionHandler(error.message);
        }
      }
    },
    async loadSkuOptions() {
      this.skuOptions = [];
      try {
        const result = await this.$axios.get(`${this.$host}/api/v1/master/sku`);
        if (result) {
          this.skuOptions = result.data;
        }
      } catch (error) {
        this.busy = false;
        this.onExceptionHandler(error.response.data.message);
      }
    },
  },
  async created() {
    await this.loadSkuOptions();
  },
};
