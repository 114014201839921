import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      description: { required },
      length: { required },
      quantity: { required },
      total_length: { required },
      unit_price: { required },
      total_price: { required },
    },
  },
  data() {
    return {
      drawerOpened: false,
      form: {
        description: null,
        length: null,
        quantity: null,
        total_length: null,
        unit_price: null,
        total_price: null
      }
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
      }
    },
    'form.length'(val){
      this.form.total_length = (parseFloat(this.form.length) *  parseFloat(this.form.quantity)).toFixed(2)
    },
    'form.quantity'(val){
      this.form.total_length = (parseFloat(this.form.length) *  parseFloat(this.form.quantity)).toFixed(2)
    },
    'form.unit_price'(val){
      this.form.total_price = (parseFloat(this.form.unit_price) *  parseFloat(this.form.total_length)).toFixed(2)
    }
  },
  methods: {
    resetForm () {
      this.form.description = null
      this.form.length = null
      this.form.quantity = null
      this.form.total_length = null
      this.form.unit_price = null
      this.form.total_price = null
      
      this.$nextTick(() => {
        this.$v.$reset();
      })
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: false
      })
    },
    async onSubmitForm() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.$emit('close-event', { 
        status: true,
        data: this.form
      })
      this.drawerOpened = false
      this.resetForm()
    }
  }
}
